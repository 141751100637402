import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import get from "lodash/get";
import first from "lodash/first";
import { navigate } from "gatsby";
import range from "lodash/range";

import ActionSnippet from "../components/ActionSnippet";
import Section from "../components/SectionTitle";
import Layout from "../components/Layout";

import { Text19 } from "./../components/Typography";
import UserGridItem from "./../components/UserGridItem";
import SearchBar from "./../components/SearchBar";
import * as StyleVars from "./../styles/cssVariables";
import TriggerWhenInView from "../components/TriggerWhenInView";
import Loader from "../components/Loader";

const ContainerWrapper = styled.div`
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1180px) {
    padding: 0 16px;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }
`;

const MobileContainerWrapper = styled(ContainerWrapper)`
  @media (${StyleVars.mediaDesktopSmallest}) {
    padding: 0;
    padding-top: 25px;
  }
`;

const SearchBarContainer = styled.div`
  display: block;

  .surprise-btn {
    path {
      fill: #b1569c;
    }
  }

  @media (${StyleVars.mediaDesktopSmallest}) {
    display: none;
  }
`;

const SnippetsContainer = styled(ContainerWrapper)`
  @media (${StyleVars.mediaDesktopSmallest}) {
    padding: 0;
  }
`;

const UserGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 25px;
  row-gap: 25px;
  padding-bottom: 10px;

  @media (${StyleVars.mediaDesktopSmallest}) {
    display: block;
  }
`;

const AppContent = styled.main`
  overflow: hidden;
  padding-bottom: 150px;
`;

const LatestReviewTitle = styled(Text19)`
  text-align: right;
  position: relative;
  margin-top: 60px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  span {
    flex-basis: content;
    flex-shrink: 0;
  }

  &:before {
    content: "";
    display: block;
    flex-basis: 70%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    background: #666;
    margin-right: 8px;
  }

  @media (${StyleVars.mediaDesktopSmallest}) {
    display: none;
  }
`;

const MobileLatestInterview = styled(Text19)`
  text-align: right;
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
  align-items: center;
  justify-content: space-between;
  display: none;
  span {
    flex-basis: content;
    flex-shrink: 0;
    font-weight: 500;
  }

  &:before {
    content: "";
    display: block;
    flex-basis: 60%;
    transform: translateY(-50%);
    height: 2px;
    background: #66666687;
    margin-right: 16px;
  }

  @media (${StyleVars.mediaDesktopSmallest}) {
    display: flex;
  }
`;

const InifiteLoadingIndicator = styled.div`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;

  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsisLoading steps(4, end) 900ms infinite;
    animation: ellipsisLoading steps(4, end) 900ms infinite;
    content: "...";
    width: 0px;
  }

  @keyframes ellipsisLoading {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsisLoading {
    to {
      width: 1.25em;
    }
  }
`;

class Index extends React.Component {
  constructor(props) {
    super(props);

    let data = get(this, "props.data.allMarkdownRemark.edges");
    const randomIndex = Math.floor(Math.random() * (data.length - 1));
    this.surprise = data[randomIndex];
    this._start = 0;
    this._end = 15;
    this._all = data;

    const newItems = range(this._start, this._end).map((i) => this._all[i]);

    this.state = {
      renderableItems: newItems,
      isLoading: false
    };
  }

  SnippetsAction(snippets, i) {
    snippets.push(<ActionSnippet key={i} />);
  }

  fetchMoreData = () => {
    if (this.state.isLoading || this._end === this._all.length) return;

    this._start = this._end;

    const increment =
      this._start + 15 > this._all.length ? this._end - this._start : 15;

    this._end = this._start + increment;

    if (this._start > this._all.length) return;

    this.setState((state) => ({
      ...state,
      isLoading: true
    }));

    setTimeout(() => {
      const newItems = range(this._start, this._end).map((i) => this._all[i]);

      this.setState((prevState) => ({
        renderableItems: [...prevState.renderableItems, ...newItems]
      }));

      setTimeout(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false
        }));
      }, 1000);
    }, 1500);
  };

  SnippetsLoop() {
    let data = this.state.renderableItems;
    let snippets = [];
    let maxRow = 1;
    let lastUsedRow = 1;
    let lastUsedCol = 1;
    let profileColSize = 1;
    let profileRowSize = 1;
    let featureColSize = 1;
    let featureRowSize = 1;
    let profileSize;
    let featureSize;
    let renderingPos;
    let profileRowStart;
    let featureRowStart;

    for (let i = 1; i <= data.length - 1; i++) {
      profileSize = data[i]?.node?.frontmatter?.profile_size || "1x1";
      featureSize = data[i]?.node?.frontmatter?.feature_size;
      renderingPos = data[i]?.node?.frontmatter?.media_pos || "profile-feature";

      if (profileSize) {
        profileColSize = parseInt(profileSize?.split("x")[0]);
        profileRowSize = parseInt(profileSize?.split("x")[1]);
      }

      const featuredImg = data[i]?.node?.frontmatter?.feature;
      const hasFeaturedImg = featureSize && featuredImg;
      if (hasFeaturedImg) {
        featureColSize = parseInt(featureSize?.split("x")[0]);
        featureRowSize = parseInt(featureSize?.split("x")[1]);
        lastUsedCol = 1;
      }

      profileRowStart = lastUsedRow + (featureRowSize - profileRowSize);
      featureRowStart = lastUsedRow;

      snippets.push(
        <UserGridItem
          key={`user-${i}`}
          slug={data[i]?.node?.fields?.slug}
          img={
            data[i]?.node?.frontmatter?.profile?.childImageSharp
              ?.gatsbyImageData
          }
          firstName={data[i]?.node?.frontmatter?.first_name || ""}
          lastName={data[i]?.node?.frontmatter?.last_name || ""}
          occupation={data[i]?.node?.frontmatter?.job || ""}
          featuredImg={
            hasFeaturedImg
              ? featuredImg?.childImageSharp?.gatsbyImageData
              : null
          }
          featureDescription={data[i]?.node?.frontmatter?.feature_description}
          profileColStart={lastUsedCol}
          profileColSize={profileColSize}
          profileRowStart={profileRowStart}
          profileRowSize={profileRowSize}
          featureColStart={lastUsedCol + profileColSize}
          featureColSize={featureColSize}
          featureRowStart={featureRowStart}
          featureRowSize={featureRowSize}
          renderingPos={renderingPos}
          hasArrow={true}
          hasBG={hasFeaturedImg}
          profileCropPos={data[i]?.node?.frontmatter?.profile_crop_pos}
          featureCropPos={data[i]?.node?.frontmatter?.feature_crop_pos}
          interviewDate={data[i]?.node?.frontmatter?.date}
        />
      );

      maxRow = Math.max(profileRowSize, featureRowSize);
      lastUsedCol += profileColSize;

      if (hasFeaturedImg) lastUsedCol += featureColSize;

      if (lastUsedCol > 3) {
        lastUsedRow = lastUsedRow + maxRow;
        lastUsedCol = 1;
      }
    }

    return snippets;
  }

  handleSearch = (query) => {
    navigate(`/search/?query=${query}`);
  };

  handleSurpriseClick = () => {
    if (!this.surprise) return;
    navigate(`${this.surprise.node.fields.slug}`);
  };

  render() {
    let latest = first(get(this, "props.data.allMarkdownRemark.edges"));
    let profileColSize = 1;
    let profileRowSize = 1;
    let featureColSize = 1;
    let featureRowSize = 1;
    let lastUsedRow = 1;
    let lastUsedCol = 1;
    const profileSize = "1x1";
    const featureSize = "2x1";
    const renderingPos = "profile-feature";

    if (profileSize) {
      profileColSize = parseInt(profileSize?.split("x")[0]);
      profileRowSize = parseInt(profileSize?.split("x")[1]);
    }

    const featuredImg = latest?.node?.frontmatter?.feature;
    const hasFeaturedImg = featureSize && featuredImg;
    if (hasFeaturedImg) {
      featureColSize = parseInt(featureSize?.split("x")[0]);
      featureRowSize = parseInt(featureSize?.split("x")[1]);
    }

    return (
      <Layout>
        <AppContent>
          <Section
            onSurprise={this.handleSurpriseClick}
            onSearch={this.handleSearch}
            numPeople={this._all.length}
          />
          <MobileContainerWrapper>
            <LatestReviewTitle>
              <span>
                Latest interview&nbsp;{latest?.node?.frontmatter?.date}
              </span>
            </LatestReviewTitle>
            <MobileLatestInterview>
              <span>Latest interview</span>
            </MobileLatestInterview>

            <UserGrid data-cy="latest">
              <UserGridItem
                slug={latest?.node?.fields?.slug}
                img={
                  latest?.node?.frontmatter?.profile?.childImageSharp
                    ?.gatsbyImageData
                }
                firstName={latest?.node?.frontmatter?.first_name || ""}
                lastName={latest?.node?.frontmatter?.last_name || ""}
                occupation={latest?.node?.frontmatter?.job || ""}
                featuredImg={
                  latest?.node?.frontmatter?.feature?.childImageSharp
                    ?.gatsbyImageData
                }
                featureDescription={
                  latest?.node?.frontmatter?.feature_description
                }
                profileCropPos={latest?.node?.frontmatter?.profile_crop_pos}
                featureCropPos={latest?.node?.frontmatter?.feature_crop_pos}
                profileColStart={lastUsedCol}
                profileColSize={profileColSize}
                profileRowStart={
                  lastUsedRow + (featureRowSize - profileRowSize)
                }
                profileRowSize={profileRowSize}
                featureColStart={lastUsedCol + profileColSize}
                featureColSize={featureColSize}
                featureRowStart={lastUsedRow}
                featureRowSize={featureRowSize}
                renderingPos={renderingPos}
                interviewDate={latest?.node?.frontmatter?.date}
                noMobilePadding
                solo
              />
            </UserGrid>
          </MobileContainerWrapper>

          <SearchBarContainer>
            <SearchBar
              onSearch={this.handleSearch}
              onSurprise={this.handleSurpriseClick}
            />
          </SearchBarContainer>

          <SnippetsContainer>
            <UserGrid>{this.SnippetsLoop()}</UserGrid>
            <Loader isLoading={this.state.isLoading} />
          </SnippetsContainer>
          <TriggerWhenInView onInView={this.fetchMoreData} />
        </AppContent>
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            first_name
            last_name
            job
            feature_size
            feature_description
            profile_size
            media_pos
            profile_crop_pos
            feature_crop_pos
            profile {
              childImageSharp {
                gatsbyImageData
              }
            }
            feature {
              childImageSharp {
                gatsbyImageData(
                  transformOptions: { fit: COVER, cropFocus: ATTENTION }
                )
              }
            }
          }
        }
      }
    }
  }
`;
