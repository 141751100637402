import React, { useRef, useEffect } from "react"

function TriggerWhenInView({ onInView }) {
  const divRef = useRef(null);

  useEffect(() => {
    const options = {
      threshold: 1.0
    }
    
    const observer = new IntersectionObserver(handleIntersection, options)

    function handleIntersection(entries) {
      const isInView = entries[0]?.isIntersecting;
      if (isInView) onInView()
    }

    if (divRef.current) observer.observe(divRef.current)

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current)
      }
    }
  }, [divRef.current, onInView])
  return <div ref={divRef} />
}

export default TriggerWhenInView
