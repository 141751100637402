import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Wrapper = styled.article`
  display: block;
  font-family: "Inter";

  // Grid
  flex-basis: calc(100% * 1 / 3 - 16px);
  width: calc(100% * 1 / 3 - 16px);
  margin: 0 8px 16px;

  background: green;

  @media (max-width: 768px) {
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 24px;
  }

  transition: transform 0.1s cubic-bezier(0.6, -0.28, 0.74, 0.05),
    box-shadow 0.1s ease-in-out;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
  }
`;

const Content = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0 24px 24px;
`;

const Name = styled.span`
  display: block;
  line-height: 1.2;
  font-size: 28px;
`;

const Title = styled(Name)`
  font-size: 20px;
  font-weight: 300;
`;

const AddWrapper = styled.div`
  transition: color 0.2s ease;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 250px;
  color: #292829;
  font-weight: 300;

  span {
    margin-top: -90px;
    line-height: 1;
  }

  &:hover {
    color: #3c3c3c;
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  display: block;
  padding-top: 100%;
  text-decoration: none;
  background: #d3d3d3;
  color: #292829;

  &:hover {
    background: #eaeaea;
    color: #3c3c3c;
  }
`;

class ActionSnippet extends React.Component {
  render() {
    return (
      <Wrapper>
        <StyledLink to="/your-setup">
          <AddWrapper>
            <span>+</span>
          </AddWrapper>
          <Content>
            <Name>Share your setup</Name>
            <Title>We'd love to see it</Title>
          </Content>
        </StyledLink>
      </Wrapper>
    );
  }
}

export default ActionSnippet;
